import React, {PropsWithChildren, ReactElement} from 'react';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';

import store from '../store';
import ScreenSpinner from './ScreenSpinner';
const persistor = persistStore(store);

const ScreenPersistGate = ({children}: PropsWithChildren): ReactElement => {
  return (
    <PersistGate loading={<ScreenSpinner />} persistor={persistor}>
      {children}
    </PersistGate>
  );
};

export default ScreenPersistGate;
