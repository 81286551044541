import {CheckIcon} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  ComponentProps,
  ForwardedRef,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Row from '../../common/Row';

type Props = Omit<ComponentProps<'input'>, 'type' | 'ref'>;

const Checkbox = ({className, onChange, ...props}: Props, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
  const inputRef = useRef<HTMLInputElement>();
  const [appearChecked, setAppearChecked] = useState(props.defaultChecked);

  useEffect(() => {
    setAppearChecked(props.checked);
  }, [props.checked]);

  const setRef = useCallback(
    (node: HTMLInputElement) => {
      inputRef.current = node ?? undefined;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    [ref]
  );

  const handleClick = useCallback(() => {
    if (props.disabled) return;
    inputRef?.current?.click();
  }, [props.disabled]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAppearChecked(e.currentTarget.checked ?? false);
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <>
      <Row
        className={classNames(
          'flex-none items-center justify-center w-[20px] h-[20px]',
          props.disabled ? 'bg-gray-100' : appearChecked ? 'bg-primary-500' : 'bg-white',
          !appearChecked && 'border-thin border-gray-100',
          className
        )}
        onClick={handleClick}
      >
        {appearChecked && <CheckIcon className={'text-white h-[14px] w-[14px]'} />}
      </Row>
      <input ref={setRef} type={'checkbox'} className={'hidden'} onChange={handleChange} {...props} />
    </>
  );
};

export default React.forwardRef<HTMLInputElement, Props>(Checkbox);
