import classNames from 'classnames';
import React, {ReactElement} from 'react';

import {Spinner} from '../../resources/Icons';

type Props = {
  className?: string;
};

const ScreenSpinner = ({className}: Props): ReactElement => {
  return (
    <div className={classNames('w-full py-[200px]', className)}>
      <Spinner className={'m-auto'} />
    </div>
  );
};

export default ScreenSpinner;
