import {StaticImageData} from 'next/legacy/image';

import eucalyptus from '../../../../public/img/eucalyptus.jpg';
import leaves from '../../../../public/img/leaves.png';
import miscanthus from '../../../../public/img/miscanthus.jpg';
import poplar from '../../../../public/img/poplar.jpg';
import rcg from '../../../../public/img/reed-canary-grass.jpg';
import willow from '../../../../public/img/willow.jpg';

export enum CropId {
  miscanthusBale = 'miscanthusBale',
  miscanthusChip = 'miscanthusChip',
  willowChip = 'willowChip',
  srcPoplar = 'srcPoplar',
  srfPoplar1600 = 'srfPoplar1600',
  srfPoplar6600 = 'srfPoplar6600',
  rcg = 'rcg',
  nitensEucalyptus1600 = 'nitensEucalyptus1600',
  nitensEucalyptus2500 = 'nitensEucalyptus2500',
  glausescensEucalyptus1600 = 'glausescensEucalyptus1600',
  glausescensEucalyptus2500 = 'glausescensEucalyptus2500',
  hempFibre = 'hempFibre',
  hempSeed = 'hempSeed',
  hempDual = 'hempDual',
}

export type Crop = {
  id: CropId;
  name: string;
  abbreviatedName?: string;
  picture: StaticImageData;
  harvestedMC: number; // Green / when harvested moisture content
  inUseMC: number; // Dried / when used moisture content
  canStagger?: boolean; // Whether the crop can be staggered
  cv: number; // calorific value
  bulkDensity: number; // tonnes/m3
  salePrice: number;
  plantationLifetime: number;
  defaultYield: number; // typical mature yield in t/ha of dry-matter (0% moisture content)
  yearsToSelfSufficiency: string;
  totalProducts?: number; // Defaults to 1
  /**
   * Get the expected yield in the given year, based on the given mature yield. The number returned represents actual
   * harvested tonnes, considering the growth in previous non-harvest years too.
   *
   * @param matureYieldGreenGreen The expected mature yield in tonnes/ha/yr of green matter (at when-harvested moisture content)
   * @param year 0-based year number, where 0 is the establishing year
   */
  getYearYield(matureYieldGreenGreen: number, year: number): number;
  /**
   * If this crop contains a secondary portion, this object will contain information about the secondary portion.
   */
  combinedData?: {
    primaryPortionName: string;
    secondaryPortionName: string;
    secondaryCrop: Pick<
      Crop,
      'harvestedMC' | 'inUseMC' | 'cv' | 'bulkDensity' | 'salePrice' | 'defaultYield' | 'getYearYield'
    >;
  };
};

const commonMiscanthus: Pick<Crop, 'getYearYield' | 'defaultYield' | 'plantationLifetime' | 'yearsToSelfSufficiency'> =
  {
    defaultYield: 10.5,
    plantationLifetime: 21,
    yearsToSelfSufficiency: '4-6',
    getYearYield: (matureYieldGreen, year) => {
      // TODO: This is using the non-agreed method of subtracting a portion from green mature yield
      if (year === 0) return 0; // Establishing year
      if (year === 1) return matureYieldGreen - 1.8;
      if (year === 2) return matureYieldGreen - 0.9;
      if (year < 22) return matureYieldGreen;
      return 0;
    },
  };

const commonEucalyptus: Pick<
  Crop,
  'harvestedMC' | 'inUseMC' | 'cv' | 'bulkDensity' | 'salePrice' | 'plantationLifetime' | 'yearsToSelfSufficiency'
> = {
  harvestedMC: 38,
  inUseMC: 25,
  cv: 3750,
  bulkDensity: 0.25,
  salePrice: 90,
  yearsToSelfSufficiency: '8-12',
  plantationLifetime: 16,
};

const getThreeYearPeriodYield = (matureYieldGreen: number, year: number): number => {
  // TODO: This is using the non-agreed method of subtracting a portion from green mature yield
  if (year === 0) return 0; // Establishing year
  if (year === 2) return (matureYieldGreen - 3.55) * 2;
  if (year % 3 === 2) return matureYieldGreen * 3;
  return 0;
};

const CROPS: {[key in CropId]: {id: key} & Crop} = {
  miscanthusBale: {
    id: CropId.miscanthusBale,
    name: 'Miscanthus (Bales)',
    picture: miscanthus,
    harvestedMC: 25, // TODO: Kevin requested this to be 15, but the spreadsheet hasn't been updated
    inUseMC: 15,
    cv: 4172,
    bulkDensity: 0.14,
    salePrice: 100,
    ...commonMiscanthus,
  },
  miscanthusChip: {
    id: CropId.miscanthusChip,
    name: 'Miscanthus (Chips)',
    picture: miscanthus,
    harvestedMC: 15,
    inUseMC: 15,
    cv: 3600,
    bulkDensity: 0.1,
    salePrice: 130,
    ...commonMiscanthus,
  },
  willowChip: {
    id: CropId.willowChip,
    name: 'SRC Willow',
    picture: willow,
    harvestedMC: 55,
    inUseMC: 25,
    canStagger: true,
    cv: 3750,
    bulkDensity: 0.2,
    salePrice: 145,
    plantationLifetime: 21,
    defaultYield: 8.25,
    yearsToSelfSufficiency: '5-7',
    getYearYield: getThreeYearPeriodYield,
  },
  srcPoplar: {
    id: CropId.srcPoplar,
    name: 'SRC Poplar',
    picture: poplar,
    harvestedMC: 50,
    inUseMC: 25,
    canStagger: true,
    cv: 3750,
    bulkDensity: 0.225,
    salePrice: 145,
    plantationLifetime: 21,
    defaultYield: 50,
    yearsToSelfSufficiency: '5-7',
    getYearYield: getThreeYearPeriodYield,
  },
  srfPoplar1600: {
    id: CropId.srfPoplar1600,
    name: 'SRF Poplar (1600)',
    picture: poplar,
    harvestedMC: 50,
    inUseMC: 25,
    cv: 3750,
    bulkDensity: 0.25,
    salePrice: 145,
    plantationLifetime: 21,
    defaultYield: 20, // 40 @ 50% MC
    yearsToSelfSufficiency: '5-7',
    getYearYield: (matureYieldGreen, year) => {
      if (year === 20) return matureYieldGreen * 15;
      return 0;
    },
  },
  srfPoplar6600: {
    id: CropId.srfPoplar6600,
    name: 'SRF Poplar (6600)',
    picture: poplar,
    harvestedMC: 50,
    inUseMC: 25,
    cv: 3750,
    bulkDensity: 0.25,
    salePrice: 145,
    plantationLifetime: 26,
    defaultYield: 10, // 20 @ 50% MC
    yearsToSelfSufficiency: '5-7',
    getYearYield: (matureYieldGreen, year) => {
      if (year === 0) return 0; // Establishing year
      if (year === 5) return (matureYieldGreen - 3) * 5; // First harvest
      if (year % 5 === 0) return matureYieldGreen * 5; // Other harvests
      return 0;
    },
  },
  rcg: {
    id: CropId.rcg,
    name: 'Reed Canary Grass',
    picture: rcg,
    harvestedMC: 15,
    inUseMC: 15,
    cv: 4172,
    bulkDensity: 0.225,
    salePrice: 70,
    plantationLifetime: 11,
    defaultYield: 4.25,
    yearsToSelfSufficiency: '5-7',
    getYearYield: commonMiscanthus.getYearYield,
  },
  nitensEucalyptus1600: {
    id: CropId.nitensEucalyptus1600,
    name: 'Nitens Eucalyptus (1600)',
    picture: eucalyptus,
    defaultYield: 21.824, // 35.20 @ 38% MC
    getYearYield: (matureYieldGreen, year) => {
      if (year === 8) return matureYieldGreen; // First harvest
      if (year === 11) return matureYieldGreen * 2; // Second harvest
      if (year === 15) return matureYieldGreen * 13; // Third harvest
      return 0;
    },
    ...commonEucalyptus,
  },
  nitensEucalyptus2500: {
    id: CropId.nitensEucalyptus2500,
    name: 'Nitens Eucalyptus (2500)',
    picture: eucalyptus,
    defaultYield: 21.824, // 35.20 @ 38% MC
    getYearYield: (matureYieldGreen, year) => {
      if (year === 5) return matureYieldGreen; // First harvest
      if (year === 8) return matureYieldGreen * 2; // Second harvest
      if (year === 11) return matureYieldGreen * 2; // Third harvest
      if (year === 15) return matureYieldGreen * 15; // Fourth harvest
      return 0;
    },
    ...commonEucalyptus,
  },
  glausescensEucalyptus1600: {
    id: CropId.glausescensEucalyptus1600,
    name: 'Glausescens Eucalyptus (1600)',
    abbreviatedName: 'Gl. Eucalyptus (1600)',
    picture: eucalyptus,
    defaultYield: 16.12, // 26.00 @ 38% MC
    getYearYield: (matureYieldGreen, year) => {
      if (year === 8) return matureYieldGreen; // First harvest
      if (year === 11) return matureYieldGreen * 2; // Second harvest
      if (year === 15) return matureYieldGreen * 13; // Third harvest
      return 0;
    },
    ...commonEucalyptus,
  },
  glausescensEucalyptus2500: {
    id: CropId.glausescensEucalyptus2500,
    name: 'Glausescens Eucalyptus (2500)',
    abbreviatedName: 'Gl. Eucalyptus (2500)',
    picture: eucalyptus,
    defaultYield: 16.12, // 26.00 @ 38% MC
    getYearYield: (matureYieldGreen, year) => {
      if (year === 5) return matureYieldGreen; // First harvest
      if (year === 8) return matureYieldGreen * 2; // Second harvest
      if (year === 11) return matureYieldGreen * 2; // Third harvest
      if (year === 15) return matureYieldGreen * 15; // Fourth harvest
      return 0;
    },
    ...commonEucalyptus,
  },
  hempFibre: {
    id: CropId.hempFibre,
    name: 'Hemp fibre (no seed)',
    picture: leaves, // TODO: Get a better picture
    harvestedMC: 69,
    inUseMC: 14,
    cv: 3800,
    bulkDensity: 0.15,
    salePrice: 150,
    plantationLifetime: 1,
    defaultYield: 6.51, // 21.00 @ 69% MC
    yearsToSelfSufficiency: '1',
    totalProducts: 2,
    getYearYield: matureYieldGreen => matureYieldGreen,
  },
  hempSeed: {
    id: CropId.hempSeed,
    name: 'Hemp Seed (some fibre)',
    picture: leaves, // TODO: Get a better picture
    harvestedMC: 20,
    inUseMC: 7,
    cv: 6392,
    bulkDensity: 0.56,
    salePrice: 1200,
    plantationLifetime: 1,
    defaultYield: 1.395,
    yearsToSelfSufficiency: '1',
    getYearYield: matureYieldGreen => matureYieldGreen,
    combinedData: {
      primaryPortionName: 'Seed',
      secondaryPortionName: 'Fibre',
      secondaryCrop: {
        harvestedMC: 69,
        inUseMC: 14,
        cv: 3800,
        bulkDensity: 0.15,
        salePrice: 150,
        defaultYield: 3.87,
        getYearYield: matureYieldGreen => matureYieldGreen,
      },
    },
  },
  hempDual: {
    id: CropId.hempDual,
    name: 'Hemp (Dual Purpose)',
    picture: leaves, // TODO: Get a better picture
    harvestedMC: 20,
    inUseMC: 7,
    cv: 6392,
    bulkDensity: 0.56,
    salePrice: 1200,
    plantationLifetime: 1,
    defaultYield: 0.93,
    yearsToSelfSufficiency: '1',
    getYearYield: matureYieldGreen => matureYieldGreen,
    combinedData: {
      primaryPortionName: 'Seed',
      secondaryPortionName: 'Fibre',
      secondaryCrop: {
        harvestedMC: 69,
        inUseMC: 14,
        cv: 3800,
        bulkDensity: 0.15,
        salePrice: 150,
        defaultYield: 4.73,
        getYearYield: matureYieldGreen => matureYieldGreen,
      },
    },
  },
} as const;

export const getAllCrops = () => CROPS;
export const getCrop = (id: CropId): Crop => CROPS[id];
