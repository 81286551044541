import '../../styles/tailwind.css';
import '../../styles/components.css';
import '../../styles/utilities.css';

import {QueryClientProvider} from '@tanstack/react-query';
import type {NextPage} from 'next';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import type {ReactElement, ReactNode} from 'react';
import {Provider} from 'react-redux';

import EnvironmentProvider from '../features/common/EnvironmentProvider';
import ErrorBoundary from '../features/common/ErrorBoundary';
import GlobalInit from '../features/common/GlobalInit';
import MatomoAnalytics from '../features/common/MatomoPageTracker';
import queryClient from '../features/query/queryClient';
import store from '../features/store';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, title: string) => ReactNode;
  title: string;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({Component, pageProps}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <>
      <Head>
        <title>Envirocrops</title>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <EnvironmentProvider>
            <ErrorBoundary>
              <MatomoAnalytics>
                <GlobalInit>{getLayout(<Component {...pageProps} />, Component.title)}</GlobalInit>
              </MatomoAnalytics>
            </ErrorBoundary>
          </EnvironmentProvider>
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default MyApp;
