import classNames from 'classnames';
import {useRouter} from 'next/router';
import React, {PropsWithChildren, ReactElement, useEffect, useState} from 'react';

import ServerCookie, {cookies} from '../../cookies';
import {detectRedirection} from '../../redirect';
import {isPublicPath} from '../../urls';
import {installAuthCookie} from '../firebase/public';
import {useCurrentUserQuery} from '../query/graphql';
import {hasFilledStartFlow, useUpdateUser} from '../query/useCurrentUser';
import {useAppDispatch} from '../store';
import NewsletterModal from '../tool/components/modals/NewsletterModal';
import PrivacyConsentModal from '../tool/components/modals/PrivacyConsentModal';
import StartFlowModal from '../tool/components/modals/StartFlowModal';
import NewScenarioModal from '../tool/components/settings/NewScenarioModal';
import {useStartFlow, validateStartFlow} from '../tool/decision/startFlow';
import {useFirebaseAuth} from './EnvironmentProvider';
import ScreenPersistGate from './ScreenPersistGate';
import ScreenSpinner from './ScreenSpinner';

const GlobalInit = ({children}: PropsWithChildren): ReactElement | null => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {data, error} = useCurrentUserQuery();
  const currentUser = data?.currentUser;
  if (error) {
    // Abort app load if we can't get the current user, which most probably means a database connection error
    throw error;
  }

  const firebaseAuth = useFirebaseAuth();
  const startFlow = useStartFlow();
  const updateUser = useUpdateUser();

  const [openModal, setOpenModal] = useState<'privacy' | 'started' | 'scenario' | 'newsletter'>();
  const [hideContent, setHideContent] = useState(true);
  const firstScenario = currentUser?.scenarios[0];
  const redirectURL = firebaseAuth ? detectRedirection(router, firebaseAuth.currentUser) : undefined;

  useEffect(() => {
    (async () => {
      if (redirectURL) {
        if (firebaseAuth?.currentUser) {
          await installAuthCookie(firebaseAuth.currentUser);
        }
        await router.replace(redirectURL);
      }
    })();
  }, [firebaseAuth?.currentUser, redirectURL, router]);

  // Determine the modal to show
  useEffect(() => {
    setOpenModal(() => {
      const isPublic = isPublicPath(router.asPath);

      // Determine open status of 'privacy' modal
      if (!isPublic && currentUser && !currentUser.consentPrivacy) {
        return 'privacy';
      }

      // Determine open status of 'started' modal
      if (!isPublic && currentUser && !hasFilledStartFlow(currentUser)) {
        const {error} = validateStartFlow(startFlow);
        if (error) {
          return 'started';
        } else {
          // Update user with get-started data saved in session storage
          void updateUser({data: {farmerType: startFlow.farmerType, farmerProfile: startFlow.farmerProfile}});
        }
      }

      // Determine open status of 'scenario' modal
      if (!isPublic && firstScenario && !firstScenario.name) {
        return 'scenario';
      }

      // Determine open status of 'newsletter' modal
      const isBannerClosed = cookies.get(ServerCookie.newsletterClosed);
      if (isPublic && !isBannerClosed) {
        return 'newsletter';
      }

      return undefined;
    });
  }, [currentUser, dispatch, firstScenario, router.asPath, startFlow, updateUser]);

  useEffect(() => {
    // Content is CSS-hidden by default, meaning the first render from server is always hidden. This avoids flickering
    // if redirection is needed. Once we determine that no redirection is needed, content is made visible.
    setHideContent(redirectURL !== false);
  }, [redirectURL]);

  // Show spinner if we are redirecting or determining if we should show redirect.
  const showSpinner = !!redirectURL || redirectURL === undefined;

  // Page content is always rendered if we are on a public path, to ensure bots (e.g. Tawk.to scraper) can see some
  // content. Server-rendered content is CSS-hidden anyway, until we determine if we should redirect
  const renderContent = isPublicPath(router.asPath) || !showSpinner;

  return (
    <>
      {showSpinner && <ScreenSpinner />}
      {renderContent && (
        <div className={classNames('h-full', hideContent && 'hidden')}>
          <ScreenPersistGate>
            <PrivacyConsentModal isOpen={!hideContent && openModal === 'privacy'} />
            <StartFlowModal isOpen={!hideContent && openModal === 'started'} />
            <NewScenarioModal
              isOpen={!hideContent && openModal === 'scenario'}
              title={'Complete your scenario'}
              scenario={firstScenario}
            />
            <NewsletterModal startOpen={!hideContent && openModal === 'newsletter'} />
          </ScreenPersistGate>
          {isPublicPath(router.asPath) ? children : <ScreenPersistGate>{children}</ScreenPersistGate>}
        </div>
      )}
    </>
  );
};

export default GlobalInit;
